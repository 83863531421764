class AliorBank11 {

    constructor() {
        this.init();
    }

    private init() {
       this.setFormFields();
       this.panelForm();
       this.menu();
       this.scrollView();
       this.hideButton();
    }

    private setFormFields() {
        let clauseInput = document.querySelector('.clauseInput');
        if(clauseInput) {
            let clauseField = clauseInput.parentElement;
            clauseField.classList.add('clause');
    
            let shortClause = document.createElement('div');
            shortClause.classList.add('shortClause');
    
            let text = document.createElement('div');
            text.classList.add('shortClauseText');
            text.innerHTML = 'Administratorem danych osobowych jest Bonnier Business (Polska) Sp. z o. o , ul. Kijowska 1, 03-738 Warszawa&nbsp;';
            
            let showMore = document.createElement('div');
            showMore.innerHTML = '(rozwiń)';
            showMore.classList.add('shortClauseButton');
            showMore.addEventListener('click', function() {
                clauseField.classList.add('-show');
            });

            text.append(showMore);
            shortClause.append(text);
            clauseField.append(shortClause);
        }

        let regulationInput = document.querySelector('.regulationInput');
        if(regulationInput) {
            let regulationField = regulationInput.parentElement;
            regulationField.classList.add('regulation');
        }
    }

    private panelForm() {
        let rodoExpandButton = document.querySelector('.bs-rodo-expand');
        let rodoShortText = document.querySelector('.bs-rodo-short');
        let rodoLongText = document.querySelector('.bs-rodo-long');

        if(rodoExpandButton) {
            rodoExpandButton.addEventListener('mouseover', function() {
                (rodoExpandButton as HTMLElement).style.display = 'none';
                (rodoShortText as HTMLElement).style.display = 'none';
                (rodoLongText as HTMLElement).style.display = 'inline-block';
            });
        }
    }

    private menu() {
        let menuHamburger = document.querySelector('.header-hamburger');
        let menuButtons = document.querySelector('.header-buttons');
        menuHamburger.addEventListener('click', function() {
            if(menuButtons.classList.contains('show')) {
                menuButtons.classList.remove('show');
            } else {
                menuButtons.classList.add('show');
            }
        });
    }

    private scrollView() {

        let url = window.location.pathname;
        let hash = window.location.hash;

        if (url.indexOf('zaloguj') !== -1) {
            let loginForm = document.querySelector('.login-form')
            window.scrollTo({
                top: (loginForm as HTMLElement).offsetTop,
                left: 0,
                behavior: 'smooth'
            });
        } else if (url.indexOf('zmien-haslo') !== -1) {
            let passwordForm = document.querySelector('.password-form')
            window.scrollTo({
                top: (passwordForm as HTMLElement).offsetTop,
                left: 0,
                behavior: 'smooth'
            });
        } else if (hash.indexOf('#register-form') !== -1) {
            let registerForm = document.getElementById('register-form');
            let registerMessage = document.querySelector('.register-message');
            let offset = 0;
            if(registerForm) {
                offset = (registerForm as HTMLElement).offsetTop;
            } else {
                offset = (registerMessage as HTMLElement).offsetTop;
            }
            window.scrollTo({
                top: offset - 100,
                left: 0,
                behavior: 'smooth'
            });
        }
    }

    private hideButton() {
        let lead_button = document.querySelector('#bs-elead-btn');
        if(lead_button) {
            lead_button.addEventListener('click', function() {
                (lead_button as HTMLElement).style.display = 'none';
                (document.querySelector('#bs-elead-blocked') as HTMLElement).style.display = 'block';
            });
        }
    }
}

let aliorBank11 = new AliorBank11();
